<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				SKU拆分
			</h3>
		</div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_0b2cd5cfcfebe08e')}}</span>
						<!-- <el-tooltip effect="dark" :content="'导出excel'" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="primary" @click="stockSyncAction()" size="small"
						icon="el-icon-refresh">{{$t('i18nn_c7948588841d75cc')}}</el-button>

					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<div class="filterAction">
				<ul class="filterConList">
					<!-- <li class="">
						<span class="red-required">{{ $t('i18nn_29991afa9781d554') }}</span>
						<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
					</li> -->
					<li>
						<span class="red-required">{{ $t('i18nn_c944a6686d996ab3') }}</span>
						<whNoSelect @changeData="changWhNo"></whNoSelect>
					</li>
					<li>
						<span>SKU:</span>
						<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.goodsSku"
							:clearable="true" style="width: 200px;"></el-input>

					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
				</ul>
			</div>
		</div>

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="ex_table" ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%"
				size="small" :max-height="$store.state.tableMaxHeight3">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')">
					<template slot-scope="scope">
						<div v-if="scope.row.mySku">
							{{scope.row.mySku.cusName}}
						</div>
					</template>
				</el-table-column> -->

				<el-table-column prop="goodsName" :label="$t('i18nn_7431e76029678ec7')">
					<template slot-scope="scope">
						<div v-if="scope.row.mySku">
							{{scope.row.mySku.goodsName}}<br />
							<span v-if="scope.row.mySku.goodsName!=scope.row.mySku.goodsNameEn">
								{{scope.row.mySku.goodsNameEn}}
							</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="goodsSku" label="SKU"></el-table-column>

				<el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>

				<!-- <el-table-column prop="stock" :label="$t('i18nn_a28112c8cca3514f')">
				
				  </el-table-column> -->



				<el-table-column prop="totalStock" :label="$t('i18nn_0640e3814d107002')">

				</el-table-column>

				<el-table-column prop="lockStock" :label="$t('i18nn_68b3bb1ef6931076')">

				</el-table-column>

				<!-- <el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
				    <template slot-scope="scope">
				      <div>
				        <el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
				          <div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
				        </el-image>
				      </div>
				    </template>
				  </el-table-column> -->

				<el-table-column prop="currentStock" :label="$t('i18nn_d91fd63094e82992')"></el-table-column>

				<el-table-column prop="isYCSync" :label="$t('i18nn_dfd9cde6fbd425c7')">
					<template slot-scope="scope">
						<div>
							<el-tag v-if="1==scope.row.isYCSync">{{$t('i18nn_02ccd2cf723f9272')}}</el-tag>
							<el-tag type="info" v-else>{{$t('i18nn_e76d885ae1c74d4f')}}</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="takeStockTypeName" :label="$t('i18nn_184333c81babf2f1')"></el-table-column>
				<!-- <el-table-column prop="takeStockType" :label="'takeStockType'"></el-table-column> -->
				<el-table-column prop="ycSyncCount" :label="$t('i18nn_3bae5212138a7193')"></el-table-column>
				<el-table-column prop="ycQt" :label="$t('i18nn_ddecfa4a8d6bb743')"></el-table-column>

				<!-- <el-table-column prop="goodsWeight" label="undefined">
				    <template slot-scope="scope">
				      <div v-if="scope.row.mySku">
				        <el-button @click="showDetList($event,scope.row)" type="warning" size="mini" icon="el-icon-view">{{$t('FormMsg.details_View')}}</el-button>
				      </div>
				    </template>
				  </el-table-column> -->
				<!-- <el-table-column prop="in_wh_source" :label="$t('i18nn_73e14fb8e120ce61')"></el-table-column> -->

				<!-- <el-table-column prop="sizeUnitName" label="undefined">
					<template slot-scope="scope">
						<div v-if="scope.row.mySku">
							{{scope.row.mySku.sizeUnitName}}
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="declarePrice" label="undefined">
					<template slot-scope="scope">
						<div v-if="scope.row.mySku">
							{{scope.row.mySku.declarePrice}}
						</div>
					</template>
				</el-table-column> -->
				<!-- <el-table-column prop="goodsWidth" label="库存总价(USD)">
				    <template slot-scope="scope">
				      <div v-if="scope.row.mySku">
				        {{scope.row.mySku.sizeUnitName}}
				      </div>
				    </template>
				  </el-table-column> -->
				<!-- <el-table-column prop="goodsHeight" :label="'SKU '+$t('i18nn_0e60303b30d5d4b4')+'*'+$t('i18nn_6cd31871f8528dd5')+'*'+$t('i18nn_93c9ea4a0e52c641')">
					<template slot-scope="scope">
						<div v-if="scope.row.mySku">
							{{scope.row.mySku.goodsLength}}*<br />{{scope.row.mySku.goodsWidth}}*<br />{{scope.row.mySku.goodsHeight}}
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="goodsWeight" :label="'SKU '+$t('i18nn_96f1a4b17e75892d')">
					<template slot-scope="scope">
						<div v-if="scope.row.mySku">
							{{scope.row.mySku.goodsWeight}}
						</div>
					</template>
				</el-table-column> -->

				<el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="120px" fixed="right">
					<template slot-scope="scope">
						<div>
							<div v-if="1==scope.row.isYCSync">
								<!-- <div> -->
								<el-button @click="SyncInventoryAction($event, scope.row, scope.$index)" type="primary" size="mini"
									icon="el-icon-refresh">{{$t('i18nn_07048291fdb26b6e')}}</el-button>

								<!-- </div> -->
								<!-- <div style="margin-top: 10px;">
									<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
											icon="el-icon-edit">{{ $t('FormMsg.Edit') }}</el-button>
									<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
										icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>

								</div> -->
							</div>
							<div v-else-if="3==scope.row.isYCSync">
								<el-button @click="ImportInventoryAction($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-refresh">{{$t('i18nn_e2ef45e045968e11')}}</el-button>
							</div>
						</div>

					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!-- 同步 -->
		<el-dialog :close-on-click-modal="false" :title="$t('i18nn_07048291fdb26b6e')" append-to-body
			:visible.sync="dialogFormVisible" width="1000px">

			<el-form ref="form" :rules="formRules" :size="'small'" :model="form" label-width="150px" style="width: 100%"
				v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">

				<el-card>
					<div>
						<!-- takeStockType -->
						<el-form-item :label="$t('i18nn_184333c81babf2f1')" required>
							<!-- <el-input v-model="form.takeStockType"></el-input> -->
							<!-- <el-input-number v-model="form.quantity"></el-input-number> -->
							<el-radio-group v-model="form.takeStockType">
								<el-radio :label="'1'">{{$t('i18nn_54e4d834b7e2c26a')}}</el-radio>
								<el-radio :label="'2'">{{$t('i18nn_38624a6aa0667143')}}</el-radio>
							</el-radio-group>

						</el-form-item>

						<el-form-item :label="$t('i18nn_f9db93b87e08763b')" required>
							<!-- <el-input v-model="form.quantity"></el-input> -->
							<el-input-number v-model="form.quantity"></el-input-number>
						</el-form-item>

					</div>
				</el-card>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" icon="el-icon-tickets" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-dialog>

	</div>
</template>
<script>
	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	// import whYiCangWhSelect from '@/components/WarehouseCenter2/components/whYiCangWhSelect.vue';

	export default {

		components: {
			// cusSelFuzzy,
			whNoSelect,
			// whYiCangWhSelect
		},

		data() {
			return {

				dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				// loading_sel: false,
				// pullYiCangWhData: [],
				openTimeYiCangWh: "",

				loading: false,
				form: {
					"productSku": null, //row.goodsSku,
					"quantity": null, //row.ycSyncCount,
					"takeStockType": null, //row.takeStockType,
				},
				formRules: {

				},
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					wh_size_unit: [],

				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					userId: "",
					whNo: "",
					goodsSku: ""
				}
			};
		},

		//创建时
		created() {},
		//编译挂载前
		mounted() {
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// if (!this.filterData.userId) {
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// 	return;
				// }
				if (!this.filterData.whNo) {
					this.$message.warning(this.$t('i18nn_6ac98f81bd978520'));
					return;
				}
				this.getPageData();
			},
			changYiCangWh(data) {
				console.log('changYiCangWh', data);
				this.form.warehouseList = data;
			},

			changCus(data) {
				console.log('changCus', data);
				this.filterData.userId = data.userId;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			//打开新增编辑，弹窗
			// openDioalog(formParm,type) {
			// 	this.dialogFormVisible = true;
			// 	let form = Object.assign({}, formParm);
			// 	console.log('form', form);
			// 	// // 重置
			// 	this.resetForm('form');
			// 	if (0 == type) {
			// 		//新增
			// 		this.dialogFormStatus = type;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 		this.form.warehouseList = [];

			// 		this.$nextTick(() => {
			// 			this.$refs.whYiCangWhSelect.init([]);
			// 		});
			// 	} else {
			// 		//修改
			// 		this.dialogFormStatus = type;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 		this.$nextTick(() => {
			// 			let wh_ids = [];
			// 			if(this.form.warehouseList && this.form.warehouseList.length>0){
			// 				wh_ids = this.form.warehouseList.map(item=>{
			// 					return item.warehouse_id;
			// 				});
			// 			}
			// 			this.$refs.whYiCangWhSelect.init(wh_ids);
			// 		});
			// 	}
			// },
			// openNew() {
			// 	if(!this.filterData.userId){
			// 		this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
			// 		return;
			// 	}
			// 	this.openTimeYiCangWh = new Date().getTime();
			// 	this.openDioalog(null,0);
			// },
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	if(!this.filterData.userId){
			// 		this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
			// 		return;
			// 	}
			// 	this.openTimeYiCangWh = new Date().getTime();
			// 	this.openDioalog(row,1);
			// },
			//库存同步
			stockSyncAction() {
				if (!this.filterData.whNo) {
					this.$message.warning(this.$t('i18nn_6ac98f81bd978520'));
					return;
				}

				let filterData = this.pageFilterData();
				this.postData(this.$urlConfig.WhThirdYiCangStockSync, filterData, '');
			},
			// //打开仓库更新
			SyncInventoryAction(event, row, index) {
				event.stopPropagation();
				// if (!this.filterData.userId) {
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// 	return;
				// }
				if (!this.filterData.whNo) {
					this.$message.warning(this.$t('i18nn_6ac98f81bd978520'));
					return;
				}
				// this.$confirm("", this.$t('tips.tipsTitle'), {
				// 		type: 'warning'
				// 	})
				// 	.then(() => {
				this.InventorySyncData(row);
				// })
				// .catch(() => {});
				// this.openTimeYiCangWh = new Date().getTime();
				// this.openDioalog(row,2);
			},
			InventorySyncData(row) {
				// let formData = {
				// 	"whNo": this.filterData.whNo,
				// 	"productList": [{
				// 		"productSku": row.goodsSku,
				// 		"quantity": row.ycSyncCount,
				// 		"takeStockType": row.takeStockType,
				// 		"lcCode": this.filterData.whNo
				// 	}]
				// };
				// this.postData(this.$urlConfig.WhThirdYiCangSyncExpr, formData, '');

				this.form = {
					"productSku": row.goodsSku,
					"quantity": row.ycSyncCount,
					"takeStockType": row.takeStockType,
				}
				this.dialogFormVisible = true;

			},

			//导入
			ImportInventoryAction(event, row) {
				event.stopPropagation();


				this.$confirm('确定同步导入?', this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						let formData = {
							"impInvVoList": row.impInvVoList,
							"whNo": row.whNo
						};
						this.postData(this.$urlConfig.WhThirdYiCangStockImpIvs, formData, '');
					})
					.catch(() => {});


			},

			//删除
			// delAction(event, row) {
			// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.delDataAction(event, row);
			// 		})
			// 		.catch(() => {});
			// },
			// //删除
			// delDataAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('delDataAction', row);
			// 	this.postData(this.$urlConfig.WhThirdYiCangConfDel + '/' + row.id, {}, 'delete');
			// },

			//提交信息
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						// let formData = Object.assign({}, this.form);
						let formData = {
							"whNo": this.filterData.whNo,
							"productList": [{
								"productSku": this.form.productSku,
								"quantity": this.form.quantity,
								"takeStockType": this.form.takeStockType,
								"lcCode": this.filterData.whNo
							}]
						};
						// let formData = {
						// 	"whNo": this.filterData.whNo,
						// 	"productList": [{
						// 		"productSku": row.goodsSku,
						// 		"quantity": row.ycSyncCount,
						// 		"takeStockType": row.takeStockType,
						// 		"lcCode": this.filterData.whNo
						// 	}]
						// };
						this.postData(this.$urlConfig.WhThirdYiCangSyncExpr, formData, '');

					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
						return false;
					}
				});
			},
			//重置输入框
			resetForm(formName) {
				console.log(formName);
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
			},

			//提交信息
			postData(url, formData, type) {
				// let _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);
					
					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						this.dialogFormVisible = false;
						this.initData();

						if ('delete' == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$message.success(this.$t('tips.submitSuccess'));
						}
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},

			//分页的筛选项数据
			pageFilterData() {
				return {
					// userId: this.filterData.userId ? this.filterData.userId : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
				};
			},
			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhThirdYiCangInventorySync, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>